import AboutUsSection from '@/components/AboutUsSection';
import BlogCarouselSection from '@/components/BlogCarouselSection';
import CompanyFeaturesSection from '@/components/CompanyFeaturesSection';
import FullWidthGallery from '@/components/FullWidthGallery';
import Navbar from '@/components/Navbar';
import PartnersSection from '@/components/PartnersSection';
import ProductsSection from '@/components/ProductsSection';
import Head from 'next/head';

export default function Home() {
  return (
    <>
      <Head>
        <title>Strona główna</title>
        <meta name="description" content="Strona główna firmy KFD" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Navbar withoutHero />
      <div className="mt-[92px]" />
      <FullWidthGallery />
      <PartnersSection />
      <AboutUsSection />
      <ProductsSection />
      <BlogCarouselSection />
      <CompanyFeaturesSection />
    </>
  );
}
