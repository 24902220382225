const partners = [
  '/partner-1.png',
  '/partner-7.png',
  '/partner-9.png',
  '/partner-14.png',
  '/partner-8.png',
  '/partner-11.png',
  '/partner-10.png',
  '/partner-12.png',
  '/partner-6.png',
  '/partner-13.png',
];

export default function PartnersSection() {
  return (
    <section className="flex flex-col gap-8 py-12 px-2">
      <div className="text-[16px] text-[#4B6176] text-center">
        Nasi partnerzy
      </div>
      <div className="flex flex-row justify-center items-center flex-wrap gap-8">
        {partners.map((item, index) => (
          <img key={index} alt={'partner ' + index} height={48} src={item} />
        ))}
      </div>
    </section>
  );
}
