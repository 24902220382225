import { defaultStyles } from '@/utils/defaultStyles';

const items = [
  {
    svg: (
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.2399 21.4999C29.5999 22.2199 30.5599 22.9399 31.3599 23.0599L36.1399 23.8599C39.1999 24.3799 39.9199 26.5799 37.7199 28.7799L33.9999 32.4999C33.3799 33.1199 33.0199 34.34 33.2199 35.22L34.28 39.8399C35.12 43.4799 33.18 44.8999 29.96 42.9999L25.4799 40.3399C24.6599 39.8599 23.34 39.8599 22.52 40.3399L18.0399 42.9999C14.8199 44.8999 12.8799 43.4799 13.7199 39.8399L14.78 35.22C14.98 34.36 14.6199 33.1399 13.9999 32.4999L10.28 28.7799C8.07996 26.5799 8.79992 24.3599 11.8599 23.8599L16.6399 23.0599C17.4399 22.9199 18.3999 22.2199 18.7599 21.4999L21.4 16.22C22.82 13.36 25.1799 13.36 26.5999 16.22L29.2399 21.4999Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 18V4"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36 18V4"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24 8V4"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    title: (
      <div className="text-white text-[25px] font-[300] text-center">
        Ponad <span className="font-bold">25 lat</span> doświadczenia
      </div>
    ),
  },
  {
    svg: (
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.34009 14.8799L24.0001 25.0999L41.54 14.9398"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24 43.2198V25.0798"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.22 25.6601V18.34C43.22 15.58 41.24 12.22 38.82 10.88L28.14 4.96C25.86 3.68 22.14 3.68 19.86 4.96L9.17999 10.88C6.75999 12.22 4.78003 15.58 4.78003 18.34V29.6601C4.78003 32.4201 6.75999 35.78 9.17999 37.12L19.86 43.0401C21 43.6801 22.5 44 24 44C25.5 44 27 43.6801 28.14 43.0401"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.4 42.8C34.8654 42.8 32 39.9346 32 36.4C32 32.8654 34.8654 30 38.4 30C41.9346 30 44.8 32.8654 44.8 36.4C44.8 39.9346 41.9346 42.8 38.4 42.8Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46 44L44 42"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    title: (
      <div className="text-white text-[25px] font-[300] text-center">
        <span className="font-bold">Szeroki</span> asortyment
      </div>
    ),
  },
  {
    svg: (
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.0001 8.22012C8.70007 9.08012 6.82007 11.8001 6.82007 14.2401V29.1001C6.82007 31.4601 8.38007 34.5601 10.2801 35.9801L18.8801 42.4001C21.7001 44.5201 26.3401 44.5201 29.1601 42.4001L37.7601 35.9801C39.6601 34.5601 41.2201 31.4601 41.2201 29.1001V14.2401C41.2201 11.7801 39.3401 9.06012 37.0401 8.20012L27.0601 4.46012C25.3601 3.84012 22.6401 3.84012 20.9801 4.46012L11.0001 8.22012Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.1001 23.7401L21.3201 26.9601L29.9201 18.3601"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    title: (
      <div className="text-white text-[25px] font-[300] text-center">
        <span className="font-bold">Wysoka</span> jakość
      </div>
    ),
  },
  {
    svg: (
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.76 23.9999L21.58 28.8399L31.24 19.1599"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.54 4.89989L29.7 7.61989C30.3 8.13989 31.42 8.55989 32.22 8.55989H35.62C37.74 8.55989 39.48 10.2999 39.48 12.4199V15.8199C39.48 16.5999 39.9 17.7399 40.42 18.3399L43.14 21.4999C44.32 22.8799 44.32 25.1399 43.14 26.5399L40.42 29.6999C39.9 30.2999 39.48 31.4199 39.48 32.2199V35.6199C39.48 37.7399 37.74 39.4799 35.62 39.4799H32.22C31.44 39.4799 30.3 39.8999 29.7 40.4199L26.54 43.1399C25.16 44.3199 22.9 44.3199 21.5 43.1399L18.34 40.4199C17.74 39.8999 16.62 39.4799 15.82 39.4799H12.36C10.24 39.4799 8.50001 37.7399 8.50001 35.6199V32.1999C8.50001 31.4199 8.08001 30.2999 7.58001 29.6999L4.88001 26.5199C3.72001 25.1399 3.72001 22.8999 4.88001 21.5199L7.58001 18.3399C8.08001 17.7399 8.50001 16.6199 8.50001 15.8399V12.3999C8.50001 10.2799 10.24 8.53989 12.36 8.53989H15.82C16.6 8.53989 17.74 8.11989 18.34 7.59989L21.5 4.89989C22.88 3.71989 25.14 3.71989 26.54 4.89989Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    title: (
      <div className="text-white text-[25px] font-[300] text-center">
        <span className="font-bold">Kompleksowa</span> obsługa
      </div>
    ),
  },
];

function FeatureBlock(props: { svg: JSX.Element; title: JSX.Element }) {
  return (
    <div className="flex flex-col items-center px-4 gap-4 border border-solid border-t-0 border-b-0 border-[#252525] py-12 w-[335px]">
      <div className="w-[96px] h-[96px] border border-solid border-[#555555] rounded-full flex flex-col justify-center items-center">
        {props.svg}
      </div>
      {props.title}
    </div>
  );
}

export default function CompanyFeaturesSection() {
  return (
    <section
      className={`${defaultStyles.paddingBig} flex flex-row justify-center items-center flex-wrap bg-[#171717]`}
    >
      {items.map((item, index) => (
        <FeatureBlock key={index} {...item} />
      ))}
    </section>
  );
}
