import { defaultStyles } from '@/utils/defaultStyles';
import Carousel from 'react-multi-carousel';
import { CustomLeftArrow, CustomRightArrow } from './CustomCarouselArrows';
import { blogPosts } from '@/constants';
import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';

function Slide(props: {
  id: number;
  img: string;
  title: string;
  desc: string;
}) {
  return (
    <div className="flex flex-col gap-4 bg-[#F4F4F4] mx-4">
      <div
        className="w-full h-[258px]"
        style={createStyleForBackgroundImage(props.img)}
      />
      <div className="flex flex-col gap-4 p-4">
        <div className="font-bold text-black text-[25px]">{props.title}</div>
        <div className="font-[300] text-[16px] text-black">
          {props.desc.slice(0, 120)}...
        </div>
        <div
          onClick={() => window.location.assign('/blog/' + props.id)}
          className="cursor-pointer self-end w-[40px] h-[40px] border border-solid border-black rounded-full flex flex-col justify-center items-center"
        >
          <svg
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.47998 15.0599L8.51998 7.99994L1.47998 0.939941"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default function BlogCarouselSection() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1424 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1424, min: 764 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 764, min: 0 },
      items: 1,
    },
  };

  return (
    <section
      className={`flex flex-col gap-8 py-12 ${defaultStyles.paddingBig}`}
      id="blog"
    >
      <div className="flex flex-col gap-4">
        <div className="text-[45px] text-black font-bold">Blog</div>
        <div className="text-[16px] font-[300] text-black">
          Nasz blog to przestrzeń, w której dzielimy się wiedzą, doświadczeniami
          i inspiracjami.
        </div>
      </div>
      <Carousel
        responsive={responsive}
        slidesToSlide={1}
        infinite
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
        className="pb-20"
      >
        {blogPosts.map((item, index) => (
          <Slide {...item} key={index} />
        ))}
      </Carousel>
    </section>
  );
}
