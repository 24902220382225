import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';
import { defaultStyles } from '@/utils/defaultStyles';

function BlockHeader(props: { text: string }) {
  return (
    <div className="flex flex-col gap-2">
      <div className="w-[1px] h-[48px] bg-[#BEBEBE]" />
      <div className="text-[14px] tracking-wider text-[#A7A7A7]">
        {props.text}
      </div>
    </div>
  );
}

export default function AboutUsSection() {
  return (
    <section
      id="about"
      className={`${defaultStyles.paddingBig} grid grid-cols-1 xl:grid-cols-2 py-12`}
    >
      <div
        className="w-full h-[364px] md:h-[564px]"
        style={createStyleForBackgroundImage('/about-1.png')}
      />
      <div className="flex flex-col gap-4 p-4 justify-center">
        <BlockHeader text="WIĘCEJ INFORMACJI O NAS" />
        <div className="text-[45px] font-bold text-black">O nas</div>
        <div className="font-[300] text-[16px] text-[#838383]">
          KFD Sp.z o.o. to polska firma o 100% kapitale rodzinnym. Firma została
          założona w 1996 r. i od początku działalność prowadzi jako spółka
          prawa handlowego. Główne rodzaje działalności to handel i produkcja.
          Wbrew panującym trendom strategia firmy KFD nie polega na oferowaniu
          klientom produktów najtańszych na świecie. Firma stawia na jakość,
          rzetelność i profesjonalizm w każdym zakresie działalności. Prawie
          30-letnie doświadczenie, ścisła współpraca z producentami
          spełniającymi kryteria najwyższej jakości przy optymalnych kosztach
          produktu oraz ciągłe prace badawczo-rozwojowe sprawiają, że KFD Sp. z
          o.o. jest postrzegany jako lider na polskim rynku.
        </div>
      </div>
      <div className="flex flex-col gap-4 p-4 justify-center">
        <BlockHeader text="INFORMACJE O ASORTYMENCIE" />
        <div className="text-[45px] font-bold text-black">
          Asortyment towarowy firmy KFD obejmuje
        </div>
        <div className="font-[300] text-black text-[16px]">
          SPA & WELLNESS (sauny I łaźnie)
          <br />
          <br />
          OUTSIDE & GARDEN (szklane balustrady)
          <br />
          <br />
          INTERIOR (WKŁADY KOMINKOWE)
        </div>
        <div className="font-[300] text-[16px] text-[#838383] mt-8">
          Nowo wybudowana siedziba firmy, oddana do użytku w 2009 r., mieści się
          w Legionowie.
        </div>
      </div>
      <div
        className="w-full h-[364px] md:h-[564px]"
        style={createStyleForBackgroundImage('/about-2.png')}
      />
    </section>
  );
}
