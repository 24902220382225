export const CustomRightArrow = ({ onClick, ...rest }: any) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  return (
    <div
      className={`absolute bottom-0 left-20 rounded-full w-[48px] h-[48px] border border-solid border-black flex flex-col justify-center items-center cursor-pointer`}
      onClick={() => onClick()}
    >
      <svg
        width="10"
        height="16"
        viewBox="0 0 10 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.47998 15.0599L8.51998 7.99994L1.47998 0.939941"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export const CustomLeftArrow = ({ onClick, ...rest }: any) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  return (
    <div
      className={`absolute bottom-0 left-0 rounded-full w-[48px] h-[48px] border border-solid border-black flex flex-col justify-center items-center cursor-pointer`}
      onClick={() => onClick()}
    >
      <svg
        width="10"
        height="16"
        viewBox="0 0 10 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.52002 15.0599L1.48002 7.99994L8.52002 0.939941"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
