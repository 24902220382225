import { useState } from 'react';

const items = [
  {
    svg: (
      <svg
        width="42"
        height="42"
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M34.5978 26.684C38.0781 27.6612 40.2308 29.0111 40.2308 30.5022C40.2308 33.4844 31.6202 35.902 20.9985 35.902C10.3768 35.902 1.76611 33.4844 1.76611 30.5022C1.76611 29.0943 3.68499 27.8124 6.82851 26.8511"
          stroke="#474747"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.2793 26.8514C10.2793 24.0432 11.9199 24.0432 11.9199 21.235C11.9199 18.4268 10.2793 18.4268 10.2793 15.6184C10.2793 12.8102 11.9199 12.8102 11.9199 10.0018"
          stroke="#474747"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.645 26.8514C29.645 24.0432 31.2856 24.0432 31.2856 21.235C31.2856 18.4268 29.645 18.4268 29.645 15.6184C29.645 12.8101 31.2856 12.8102 31.2856 10.0018"
          stroke="#474747"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.9622 31.5797C19.9622 28.8826 21.6028 28.8826 21.6028 26.1856C21.6028 23.4884 19.9622 23.4884 19.9622 20.7912C19.9622 18.094 21.6028 18.094 21.6028 15.3967C21.6028 12.6993 19.9622 12.6993 19.9622 10.0019C19.9622 7.30455 21.6028 7.30447 21.6028 4.60712"
          stroke="#474747"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    title: 'Sauny',
    desc: 'Najbogatsza oferta materiałów, profesjonalne doradztwo',
    img: '/sauna.png',
    subTitle: 'OUTSIDE & GARDEN',
    id: 'sauny',
  },
  {
    svg: (
      <svg
        width="42"
        height="42"
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.0428 21.0426C29.5741 21.0426 36.4901 20.574 36.4901 19.996C36.4901 19.418 29.5741 18.9495 21.0428 18.9495C12.5115 18.9495 5.59546 19.418 5.59546 19.996C5.59546 20.574 12.5115 21.0426 21.0428 21.0426Z"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.52086 19.996C5.52086 19.996 0.486028 22.1898 1.28797 26.5813C1.77047 29.224 5.52086 29.4839 5.52086 29.4839"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.18826 20.6027C8.18826 20.6027 5.29297 23.9386 5.29297 26.8845C5.29297 29.8304 8.42952 31.7367 8.42952 31.7367L11.9039 30.2637"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.3167 20.9493C14.3167 20.9493 11.4214 24.3108 11.4214 27.6235C11.4214 30.9361 14.5579 33.0797 14.5579 33.0797L18.0805 31.6934"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.5647 19.996C36.5647 19.996 41.5995 22.1898 40.7976 26.5813C40.3151 29.224 36.5647 29.4839 36.5647 29.4839"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.8972 20.6027C33.8972 20.6027 36.7925 23.9386 36.7925 26.8845C36.7925 29.8304 33.656 31.7367 33.656 31.7367L30.1816 30.2638"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.769 20.9493C27.769 20.9493 30.6643 24.3108 30.6643 27.6235C30.6643 30.9361 27.5277 33.0797 27.5277 33.0797L24.0051 31.6934"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.5196 21.0793L17.0624 27.2405C16.3941 28.9163 16.9467 30.7622 18.4818 31.9822L21.1168 34.0762L23.6988 31.9731C25.1936 30.7555 25.7272 28.9378 25.0751 27.2847L22.6272 21.0793"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.75244 36.5882H39.3333"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.22388 31.3833L5.41518 36.3928"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.3005 33.2297L11.4614 36.3927"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.2109 33.2297L31.05 36.3927"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.8615 31.3833L36.6702 36.3928"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.7209 34.2138H25.4279V36.3745"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6575 36.3745V34.2138H18.5777"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.7947 5.95391V5.58493"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.7947 13.3726V8.07335"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.00557 11.1866L5.95996 11.1395"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.4542 15.7826L7.40186 12.6349"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.386 15.3189L35.8589 10.7966"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.6987 12.0016L28.7269 7.2993"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.7886 14.1929L25.8508 14.047"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.7218 12.2102L12.6804 7.50504"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.7204 14.3916L15.6211 14.1668"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    title: 'spa & wellness',
    desc: 'Najbogatsza oferta materiałów, profesjonalne doradztwo',
    img: '/spa.png',
    subTitle: 'OUTSIDE & GARDEN',
    id: 'spa',
  },
  {
    svg: (
      <svg
        width="42"
        height="42"
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.09717 4.26669H17.4151V30.565H3.09717V4.26669Z"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.415 4.26669H31.733V30.565H17.415V4.26669Z"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.4204 35.3732L31.7332 30.565V4.26669L39.4204 9.07487V35.3732Z"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.09706 30.565L1.10107 31.5299V33.011H31.733V30.565"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.4204 35.3732L40.7595 36.2097V37.9659H39.4204L31.7332 33.011"
          stroke="black"
          strokeWidth="1.23047"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    title: 'Balustrady',
    desc: 'Najbogatsza oferta materiałów, profesjonalne doradztwo',
    img: '/glass-fence.png',
    subTitle: 'OUTSIDE & GARDEN',
    id: 'garden',
  },
  {
    svg: (
      <svg
        width="42"
        height="42"
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30.8477 34.8868H1.93555V6.99637H30.8477"
          stroke="black"
          strokeWidth="1.14844"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.74329 32.4259V16.785C5.74329 13.1344 8.83923 10.1749 12.6582 10.1749H20.1251C23.9441 10.1749 27.04 13.1344 27.04 16.785V32.426"
          stroke="black"
          strokeWidth="1.14844"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.6439 40.6328L30.9064 34.8868V6.99637L37.6439 1.2504V40.6328Z"
          stroke="black"
          strokeWidth="1.14844"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.6439 1.2504H40.0985V40.6329H37.6439V1.2504Z"
          stroke="black"
          strokeWidth="1.14844"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.8599 32.4259C13.8599 32.4259 10.4077 31.6169 10.4077 27.8348C10.4077 24.3457 14.0963 23.2102 14.8524 20.5636C15.6086 17.917 14.1568 15.5982 14.1568 15.5982C14.1568 15.5982 17.3572 16.641 18.3969 19.9493C19.4366 23.2575 16.471 23.6664 17.6407 25.4787C18.4285 26.6991 21.0297 25.5515 20.4177 23.0268C20.4177 23.0268 25.161 28.497 19.8821 32.4259"
          stroke="black"
          strokeWidth="1.14844"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    title: 'Interior',
    desc: 'Najbogatsza oferta materiałów, profesjonalne doradztwo',
    img: '/interior.png',
    subTitle: 'OUTSIDE & GARDEN',
    id: 'interior',
  },
];

function GalleryCard(props: {
  svg: JSX.Element;
  title: string;
  desc: string;
  img: string;
  subTitle: string;
  id: string;
}) {
  const [isMouseOver, setIsMouseOver] = useState(false);

  const createStyleForBackgroundImage = (image: string) => ({
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  });

  return (
    <div
      onClick={() => {
        if (window.location.pathname !== '/') {
          window.location.assign('/');
        } else {
          setTimeout(() => {
            const element = document.getElementById(props.id);
            if (element) {
              window.scrollTo({
                top: element.offsetTop - 180,
                behavior: 'smooth',
              });
            }
          }, 0);
        }
      }}
      onMouseOver={() => setIsMouseOver(true)}
      onMouseOut={() => setIsMouseOver(false)}
      style={createStyleForBackgroundImage(props.img)}
      className={`relative h-[532px] md:h-[732px] transition-all duration-300 px-4 ${isMouseOver ? '' : 'grayscale-[70%]'}`}
    >
      <div className="w-full h-full">
        <div className="flex flex-col gap-4 justify-end w-full h-full">
          <div className="bg-white w-[85px] h-[85px] rounded-full flex flex-col justify-center items-center">
            {props.svg}
          </div>

          <div className="font-bold text-[35px] text-white uppercase">
            {props.title}
          </div>
          <div
            className={`text-white text-[16px] font-[300] transition-all duration-300 transform ${
              isMouseOver
                ? 'opacity-100 max-h-full scale-100 translate-y-0'
                : 'opacity-0 max-h-0 scale-95 translate-y-2'
            }`}
          >
            {props.desc}
          </div>
          <button
            onClick={() => {
              if (window.location.pathname !== '/') {
                window.location.assign('/');
              } else {
                setTimeout(() => {
                  const element = document.getElementById(props.id);
                  if (element) {
                    window.scrollTo({
                      top: element.offsetTop - 180,
                      behavior: 'smooth',
                    });
                  }
                }, 0);
              }
            }}
            className={`border-white border-solid h-[79px] bg-transparent outline-none rounded-[72px] cursor-pointer px-4 max-w-[319px] flex flex-row justify-between items-center mb-8 transition-all duration-300 transform ${
              isMouseOver
                ? 'opacity-100 max-h-full scale-100 translate-y-0'
                : 'opacity-0 max-h-0 scale-95 translate-y-2'
            }`}
          >
            <div className="text-[25px] text-white">Sprawdź</div>
            <div className="w-[48px] h-[48px] border border-solid border-white rounded-full flex flex-col justify-center items-center">
              <svg
                width="10"
                height="16"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.47998 15.0599L8.51998 7.99994L1.47998 0.939941"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default function FullWidthGallery() {
  return (
    <section className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4">
      {items.map((item, index) => (
        <GalleryCard key={index} {...item} />
      ))}
    </section>
  );
}
