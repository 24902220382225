import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';
import { defaultStyles } from '@/utils/defaultStyles';

function ProductBox(props: { img: string; title: string; route: string }) {
  return (
    <div
      onClick={() => window.open(props.route, '_blank')}
      className="p-4 text-[20px] font-bold text-white text-center w-[270px] h-[270px] flex flex-col justify-center items-center cursor-pointer rounded-[8px] overflow-hidden"
      style={createStyleForBackgroundImage(props.img, {
        withDarkOverlay: true,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      })}
    >
      {props.title}
    </div>
  );
}

function ProductCategory(props: {
  category: string;
  id: string;
  items: { img: string; title: string; route: string }[];
}) {
  return (
    <div className="flex flex-col gap-4" id={props.id}>
      <div className="text-black text-[40px] font-bold">{props.category}</div>
      <div className="flex flex-row gap-4 flex-wrap">
        {props.items.map((item, index) => (
          <ProductBox key={index} {...item} />
        ))}
      </div>
    </div>
  );
}

const items = [
  {
    category: 'Sauny',
    id: 'sauny',
    items: [
      {
        img: '/prod-sauna-1.png',
        title: 'Sauny na wymiar',
        route: 'https://wannyspa.pl/sauny-wellness/sauny/sauny-na-wymiar/',
      },
      {
        img: '/prod-sauna-2.png',
        title: 'Sauny',
        route: 'https://wannyspa.pl/sauny-wellness/sauny/',
      },
      {
        img: '/prod-sauna-3.png',
        title: 'Łaźnie parowe hammam',
        route: 'https://wannyspa.pl/sauny-wellness/laznie-parowe/',
      },
      {
        img: '/prod-sauna-4.png',
        title: 'Sauny infrared',
        route: 'https://wannyspa.pl/sauny-wellness/sauny/sauny-infrared/',
      },
    ],
  },
  {
    category: 'Wanny spa',
    id: 'spa',
    items: [
      {
        img: '/prod-spa-1.png',
        title: 'Wanny spa, jaccuzi ogrodowe',
        route: 'https://wannyspa.pl/wanny-spa/',
      },
      {
        img: '/prod-spa-2.png',
        title:
          'Systemy prysznicowe i wellness do profesjonalnych i domowych SPA',
        route: 'https://www.aquaformsrl.com/collections/',
      },
    ],
  },
  {
    category: 'Outside & Garden',
    id: 'garden',
    items: [
      {
        img: '/prod-garden-1.png',
        title: 'Wysuwane szklane balustrady',
        route: 'https://szklanebalustrady.eu/',
      },
      {
        img: '/prod-garden-2.png',
        title: 'Duże parasole zewnętrzne',
        route: 'https://www.may-online.com/en/',
      },
    ],
  },
  {
    category: 'Interior',
    id: 'interior',
    items: [
      {
        img: '/prod-interior-1.png',
        title: 'Wkłady i piece kominkowe',
        route: 'https://www.kfdesign.pl/',
      },
    ],
  },
];

export default function ProductsSection() {
  return (
    <section
      id="products"
      className={`${defaultStyles.paddingBig} flex flex-col gap-8 py-12`}
    >
      {items.map((item, index) => (
        <ProductCategory key={index} {...item} />
      ))}
    </section>
  );
}
